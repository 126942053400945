var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"d-flex justify-start align-center grey lighten-3"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v("Entire Offspring")])]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.littersHeaders,"items":_vm.offsprings,"no-data-text":"This dog does not have any offspring","items-per-page":-1},scopedSlots:_vm._u([{key:"item.identifier",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                  name: 'module-lhl-dogs-individual',
                  params: { dogId: item.id },
                }}},[_vm._v(_vm._s(item.name))])]}},{key:"item.sire",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                  name: 'module-lhl-dogs-individual',
                  params: { dogId: item.sire.id },
                }}},[_vm._v(_vm._s(item.sire.name))])]}},{key:"item.dam",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                  name: 'module-lhl-dogs-individual',
                  params: { dogId: item.dam.id },
                }}},[_vm._v(_vm._s(item.dam.name))])]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }